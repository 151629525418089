import React from 'react';
import { Helmet } from 'react-helmet';
import { InstantSearch } from 'react-instantsearch-dom';
import afostoInstantSearch from '@afosto/instant-search-client';
import Layout from '../../../../components/Layout';
import Grid from '../../../../components/Grid';
import Typography from '../../../../components/Typography';
import Hits from '../../../../components/instantSearch/Hits';
import HitsPerPage from '../../../../components/instantSearch/HitsPerPage';
import SearchBox from '../../../../components/instantSearch/SearchBox';
import RefinementList from '../../../../components/instantSearch/RefinementList';
import Stats from '../../../../components/instantSearch/Stats';
import Pagination from '../../../../components/instantSearch/Pagination';
import ClearRefinements from '../../../../components/instantSearch/ClearRefinements';
import CurrentRefinements from '../../../../components/instantSearch/CurrentRefinements';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const searchProxyID = process.env.GATSBY_SEARCH_PROXY_ID;
const searchIndex = process.env.GATSBY_SEARCH_INDEX;

const localizations = [
  {
    locale: 'en',
    slug: '/apps/instant-search/demo/',
  },
];

const demo = ({ location }) => {
  const searchClient = afostoInstantSearch(searchProxyID);

  return (
    <Layout location={location} locale="nl-NL" localizations={localizations} slug="/nl/apps/instant-search/demo/">
      <Helmet
        title="Instant Search Demo | Afosto"
        meta={[
          {
            name: 'description',
            content: 'Ervaar razendsnelle zoek resultaten met de Instant Search demo van Afosto.',
          },
        ]}
      >
        <html lang="nl-NL" />
      </Helmet>
      <Breadcrumbs location={location} locale="nl" className="mt-24" wrapper={props => <Grid container {...props} />} />
      <Grid container className="pt-16 pb-56">
        <Typography variant="h1" className="mb-16 mt-32" align="center">
          Instant Search demo
        </Typography>
        <Typography
          variant="subtitle-1"
          component="h2"
          weight="normal"
          className="mb-56 mt-16"
          align="center"
          color="text-secondary"
        >
          Zoek door onze demo database en ervaar zelf de razendsnelle resultaten met search as you type!
        </Typography>
        <InstantSearch searchClient={searchClient} indexName={searchIndex}>
          <Grid row direction="row-reverse">
            <Grid column xs={12} md={9}>
              <SearchBox placeholder="zoek bijv. op 'spider man'" />
              <Stats label="{n} resultaten gevonden" />
              <Hits emptyMessage="Geen resultaten gevonden voor deze zoekopdracht" />
              <div className="d-flex justify-content-between align-items-center pt-32">
                <HitsPerPage
                  currentRefinement={10}
                  defaultRefinement={10}
                  items={[
                    { value: 10, label: 'Toon 10 resultaten' },
                    { value: 20, label: 'Toon 20 resultaten' },
                    { value: 50, label: 'Toon 50 resultaten' },
                  ]}
                />
                <Pagination />
              </div>
            </Grid>
            <Grid column xs={12} md={3} className="mt-40 mt-md-0">
              <div className="mb-16 mt-16">
                <CurrentRefinements
                  labelTranslations={{
                    main_genre: 'Genre',
                    release_year_range: 'Publicatie jaar',
                  }}
                />
              </div>
              <div className="mb-40">
                <ClearRefinements label="Verwijder alle filters" />
              </div>
              <RefinementList attribute="main_genre" title="Genre" />
              <RefinementList attribute="release_year_range" title="Publicatie jaar" />
            </Grid>
          </Grid>
        </InstantSearch>
      </Grid>
    </Layout>
  );
};

export default demo;
